import { createRoot } from 'react-dom/client';
import { Router } from 'react-router';
import { ErrorBoundary } from 'react-error-boundary';

import 'common/assets/blueprint-overrides.scss';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import '@blueprintjs/select/src/blueprint-select.scss';
import '@blueprintjs/datetime/src/blueprint-datetime.scss';
import './global.scss';
import './i18n';

import moment from 'moment';

import { AuthProvider } from './context/auth-context';
import App from './App';
import history from 'app-history';
import ReloadPrompt from 'ReloadPrompt';
import AppError from 'AppError';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <Router history={history}>
    <ErrorBoundary FallbackComponent={AppError}>
      <AuthProvider>
        <App />
      </AuthProvider>
    </ErrorBoundary>
    <ReloadPrompt />
  </Router>
);

// Set mock dates
if (import.meta.env.VITE_MOCK_TODAY) {
  moment.now = () => +new Date(import.meta.env.VITE_MOCK_TODAY as string);
}
